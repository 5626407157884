.navbar-brand {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    margin: auto;
}

.navbar-toggle {
    z-index:3;
}

div.navbar-collapse ul.nav li {
    text-align: center;
}

.navbar-brand span {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    margin-top: 20px;
}

@media (max-width: 991px) {
    .navbar-brand span {
        position: unset;
    }
}

nav#topbar.navbar {
    transition: background-color 1s ease-in-out;
}

#new_version_alert {
    display: none;
    opacity: 0;
}
#new_version_alert.active {
    opacity: inherit;
    display: block;
    transition-property: visibility, opacity;
    transition-duration: 1s;
}
@media (max-width: 767px)
{
    .collapse > .notif-item, .collapsing > .notif-item {
        display:none;
    }
}
.notif-item {
    padding: 0px;
    margin: 0px;
}

.notif-item #bell-icon {
    color: #ffffff;
}

.notif-item > li > a {
    height: 63.4px;
    padding-top: 22px;
}