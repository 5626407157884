body {
  margin: 0;
  padding: 0;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: auto;
}

.overflow-wrap {
  overflow-wrap: break-word;
}

.breadcrumb>li>a.active {
  color: #bbbbbb;
}

.breadcrumb>li>a.active:hover, a:focus {
  text-decoration: none;
}

.bg-color {
  /* Full height */
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0em;
  left: 0em;
}

.space-left {
  margin-left: 0.5em;
}

.space-right {
  margin-right: 0.5em;
}

.button-spacer button {
  margin: 0.25em 0.5em 0.25em 0.5em;
}


.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

div.counters .label {
  margin: 0.2em;
  display: inline-block;
}

.nav-tabs {
  text-align: center;
  margin-bottom: 10px;
}

.nav-tabs > li {
  float:none;
  display:inline-block;
  *display:inline; /* ie7 fix */
   zoom:1; /* hasLayout ie7 trigger */
}

ul.nav-tabs > li.active span.label:not(.ignore-active) {
  background-color: #2196f3;
}

.panel-title a {
  /* This is a hack to make the entire bar clickable */
  display: block;
}

.table.no-border tr td, .table.no-border tr th {
  /* https://codepen.io/netsi1964/pen/ogVQqG */
  border-width: 0;
}


.form-group.form-height-fix {
      width: 100%;
      display: inline-block;
}

.multiline {
  white-space: pre-line;
}

.multiline-left-center {
  white-space: pre-line;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: table;
}

/* Override bootstrap-paper-bs */
.table > tbody > tr > td {
  vertical-align: middle;
}

.help-block {
  white-space: pre-line;
  color: #9c27b0;
}

@media (max-width: 767px) {
  #topbar .navbar-brand {
    text-align: justify;
  }

  #topbar .navbar-brand span {
    margin-left: 5px;
  }

  .no-phone-padding {
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-right: 5px;
  }

  .jumbotron {
      padding-left: 5px !important;
      padding-right: 5px !important;
  }

  .input-group-addon {
      padding: 2px;
  }
}

.dropdown-menu {
  min-width: 120px;
}
.text-right-768 {
  text-align: left;
}

.form-group-cols-right {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 768px) {
  blockquote {
    /* margin: 0; */
    padding-right: 0;
    padding-left: 2px;
  }
}
.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

@media (min-width: 768px) {
  .form-group-cols-right {
      margin-left: 5px;
  }
  .flex-center-768 {
      display: flex;
      align-items: center;
  }

  .text-right-768 {
      text-align: right
  }
}


.help-block {
  font-size: 15px;
}



.inherit-line-height {
  line-height: inherit;
}

.profile-img {
  width: 200px;
  height: 200px;
  border-radius: 50em;
  object-fit: cover;
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.progress {
  height: 23px;
}

.color-info {
  color: #9c27b0!important;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
  background-color: unset;
  border: unset;
  border-radius: unset;
  color: unset;
  cursor: pointer;
  display: inline;
  font: unset;
  text-transform: unset;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  vertical-align: unset;
}

.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
}

input[type="radio"]{
  box-shadow: none!important;
}

.padded-table td {
  padding: 10px;
}

.no-underline {
  text-decoration: none;
}

.no-click-pager li > a[role=button] {
  cursor: default;
  color: #666666;
}
.no-click-pager li > a[role=button]:hover,
.no-click-pager li > a[role=button]:focus {
  background-color: inherit;
}